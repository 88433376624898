 import React, { useEffect, useState } from "react";
import Plot from 'react-plotly.js';
import { Slider } from "@material-ui/core";


function valuetext(value) {
    return `${value}`;
}

const marks = [
    {
        value: 0,
        label: '0m',
    },
    {
        value: 4,
        label: '4m',
    },
    {
        value: 8,
        label: '8m',
    },
    {
        value: 12,
        label: '12m',
    },
];


function scaleThres(x, thres){
    let fx = 7. / ((x + 2.) / 10);
    return fx + thres;
}


const ValueListPlot = React.memo(({ inputMsg }) => {
    const [targetList, setTargetList] = useState([
        { x: [], y: [], type: 'scattergl', mode: 'markers' },
        { x: [], y: [], type: 'scattergl', mode: 'markers' },
        { x: [], y: [], type: 'scattergl', mode: 'markers' }]);
    const [value, setValue] = useState([.2, 3])

    const append_list = (msg) => {
        setTargetList(tlist => {

            const res = tlist.map((tl, i) => {
                var arr = {
                    x: [...tl.x, msg.header.timestamp_read],
                    y: [...tl.y, msg.values_abs[i]], type: 'scattergl', mode: 'markers'
                };


                if (arr.x.length > 512) {
                    arr.x.shift();
                    arr.y.shift();
                }
                return arr;
            });
            setTargetList(res);


        });
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    useEffect(() => {
        if (inputMsg.header.timestamp_read) {
            append_list(inputMsg);
        }

    }, [inputMsg]);

    return (
        <>

            <Plot style={{float:"rigth", paddingLeft: 60, height: "500px" }} data={targetList}
                layout={{
                    paper_bgcolor: 'rgba(0,0,0,0)',
                    plot_bgcolor: 'rgba(0,0,0,0)',
                    showlegend: true,
                    legend: { "orientation": "h" },

                    xaxis: { showgrid: true, showline: true, gridwidth: 1, gridcolor: '#bbbbbb' },
                    yaxis: { showgrid: true, showline: true, gridwidth: 1, gridcolor: '#bbbbbb' }
                }} />
        </>
    );
});

export default ValueListPlot;
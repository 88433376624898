import React, { useState } from 'react'
import { red, green } from '@material-ui/core/colors';
import { Slider } from '@material-ui/core';

function valuetext(value) {
    return `${value}degree`;
}


const RotationRangeSlider = ({realValue, dispatch }) => {
    const [state, setState] = useState({dispatched: true, value: 0 })
    const handleChange = (event, value) => {
        setState({dispatched: false, value: value});

    }
    const marks = [
           {
        value: realValue,
            label: String(realValue) + 'm',
    },
    
    

    ]




return (
    <div style={{minHeight: 40}}>
<Slider
    style={{minWidth: 400, width: "100%", float: "left",  color: state.dispatched ? green[300]: red[300] }}
    value={state.value}
    onChange={handleChange}
    onChangeCommitted={(e) => {
        dispatch({ type: "rotation:pos", payload: state.value });
        setState({...state, dispatched: true});
    }}
    valueLabelDisplay="auto"
    getAriaValueText={valuetext}
    marks={marks}
    max={90}
    min={-90}
    step={1}
/>
</div>);
}

export default RotationRangeSlider;

import React from 'react'
import Plot from 'react-plotly.js';

export const RawDataPlot = React.memo(({ raw_data, xlabel, ylabel }) => {
 return (
    <Plot style={{ width: "100%", minHeight: 400 }} data={raw_data}
      layout={{
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)',
        autosize: true,
        xaxis: {
          title: { text: xlabel },
          showgrid: true, showline: true, gridwidth: 1, gridcolor: '#bbbbbb'
        },
        showlegend: true,
        legend: { "orientation": "h" },
        yaxis: {title: { text: ylabel }, showgrid: true, showline: true, gridwidth: 1, gridcolor: '#bbbbbb' }

      }}
      />
  )
});
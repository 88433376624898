import { Button, List, ListItem, ListItemText, Modal } from '@material-ui/core';
import React, { useState } from 'react';
//import { Button, Modal, List, ListItem, ListItemText } from '@mui/material';

const FirmwareControl = ({ names, dispatchFunction, isOpen, handleClose }) => {
  const handleItemClick = (name) => {
    dispatchFunction({ type: 'radar:flash', payload: name });
    alert('firmware update send please wait 2 minutes\nThen press deactivate activate and check the serial output');
    handleClose();
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <div style={{ width: '300px', margin: 'auto', marginTop: '100px', backgroundColor: '#fff', padding: '20px' }}>
        <h2>Available Firmwares</h2>
        <List>
          {names.map((name) => (
            <ListItem button key={name} onClick={() => handleItemClick(name)}>
              <ListItemText primary={name} />
            </ListItem>
          ))}
        </List>
        <Button variant="contained" onClick={handleClose}>close</Button>
      </div>
    </Modal>
  );
};

export default FirmwareControl;

import React, { useState, useEffect, useMemo, useReducer } from "react";
import "./App.css";
import useWebSocket from "react-use-websocket";
import {
  AppBar,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Toolbar,
  Typography,
} from "@material-ui/core";
import TargetListPlot from "./component/targetList";

import { RawDataPlot } from "./component/rawDataPlot";
import WebsocketIndicator from "./component/webSocketStateShow";
import HeaderVis from "./component/headervis";
import DownloadWidget from "./component/downloadWidget";
import Copyright from "./component/copyright";
import MenuButtons from "./component/MenuButtons";
import Radar from "./component/radar";
import ComputingRangeSlider from "./component/computingRangeSlider";
import { green, red } from "@material-ui/core/colors";
import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import RotationRangeSlider from "./component/rotation_stage";
import SerialComm from "./component/serial_sender";
import { SerialEasy } from "./component/serialEasy";

import ValueListPlot from "./component/valueList";
import { UploadManager } from "./component/uploadManager";
import { IpAddress } from "./component/ipAddress";
import ControlPanel from "./component/controlPanel";
import { SerialLine } from "./component/serialLine";
import FirmwareControl from "./component/firmwareControl";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(1),
  },
  control: {
    padding: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "flex",
  },
  websock: {
    position: "relativ",
  },
}));

const handleRawData = (msg, state) => {
  const plotType = "scattergl";

  if (state.getData) {
    return {
      rawData: [
        { y: msg.payload.channel_0, type: plotType, name: "channel 0" },
        { y: msg.payload.channel_1, type: plotType, name: "channel 1" },
        { y: msg.payload.channel_2, type: plotType, name: "channel 2" },
      ],
      specData: [
        {
          x: msg.payload.xax_spec,
          y: msg.payload.spec_0,
          type: plotType,
          name: "channel 0",
        },
        {
          x: msg.payload.xax_spec,
          y: msg.payload.spec_1,
          type: plotType,
          name: "channel 1",
        },
      ],
    };
  } else {
    return {};
  }
};

const handleType = (msg, state) => {
  switch (msg.type) {
    case "zablue:status":
      return { zablue: msg.payload };
    case "radar:status":
      return { status: msg.payload };
    case "radar:raw_prep":
      return handleRawData(msg, state);
    case "radar:serial_result":
      return { serialMsg: msg.payload };
    case "radar:tier1":
      return { targets: msg.payload };
    case "radar:flash":
      console.log(msg);
      return {};
    case "radar:firmware":
      if (msg.payload){
        console.log(msg);
        return {firmware: msg.payload}
      }
    case "GETDATA":
      return { getData: !state.getData };
    default:
      return {};
  }
};

const initialState = {
  targets: { targets: [0, 0, 0], header: { timestamp_read: 0 } },
  raw_data: [],
  serialMsg: "",
  status: {
    downloads: [],
    activate: false,
    process_status: { min_dist: 0, max_dist: 5, frequency_hz: 0 },
    radar_recorder: { description: "", postfix: "", started: true },
    ip_addr: [],
  },
  firmware: {filenames: []},
  getData: true,
  rotation_pos: 0,
};

const websocketReducer = (state, action) => {
  if (action.type === "msg_list") {
    try {
      const res = action.payload.map((value) => handleType(value, state))
        .reduce((acc, current) => {
          return { ...acc, ...current };
        });

      return { ...state, ...res };
    } catch (e) {
      console.log(e);
      return state;
    }
  } else {
    return { ...state, ...handleType(action, state) };
  }
};

function App() {
  const classes = useStyles();
  const [state, dispatch] = useReducer(websocketReducer, initialState);

   const [modalOpen, setModalOpen] = useState(false);

    const openModal = () => {
      setModalOpen(true);
    };

    const closeModal = () => {
      setModalOpen(false);
    };

    const dispatchFunction = (action) => {
      // Hier können Sie Ihre Dispatch-Funktionalität implementieren
      console.log('Dispatched action:', action);
    };



  /** websocket region */
  const STATIC_OPTIONS = useMemo(() => ({
    onError: (err) => console.log("err"),
    onOpen: () => console.log("opened"),
    shouldReconnect: (closeEvent) => {
      console.log("reconnect attempt");
      return true;
    },
    reconnectAttempts: 1000,
    reconnectInterval: 3000, //Will attempt to reconnect on all close events, such as server shutting down
  }), []);

  var host = "";
  if (window.location.hostname === "localhost") {
    host = "ws://10.8.0.24:9000";

    //host = "ws://livedemo120-ws.wellenzahl.online";
  } else {
    host = "ws://".concat(window.location.hostname, ":9000");
    //   host = "ws://livedemo120-ws.wellenzahl.online";
  }

  const [sendMessage, lastMessage, readyState] = useWebSocket(
    host,
    STATIC_OPTIONS,
  );

  const dispatchFun = (msg) => {
    //console.log("for dispatching", readyState, msg);
    if (readyState === 1) {
      sendMessage(JSON.stringify(msg));
    }
  };

  useEffect(() => {
    if (readyState === 1) {
      if (lastMessage !== null) {
        try {
          const msg = JSON.parse(lastMessage.data);
          dispatch(msg);
        } catch (e) {
          console.log(e);
        }
      }
      setTimeout(() => {
        dispatchFun({ type: "ping" });
      }, 150);
    } else {
      console.log(readyState);
    }
  }, [lastMessage, readyState]);

  /**
   * handle region
   */
  /**
   * layout region
   */
  return (
    <main>
      <AppBar position="static" style={{ marginBottom: 30 }}>
        <Toolbar>
          <span className={classes.title}>
            <MenuButtons dispatch={dispatchFun} />
            <Typography variant="h3" className={classes.title}>
              RAPID
            </Typography>

            <Typography variant="body2" className={classes.title}>
              <ul>
                {state.status.ip_addr.map((item) => (
                  <li key={item.inteface}>
                    {item.interface + ": " + item.address}
                  </li>
                ))}
              </ul>
            </Typography>
          </span>

          <Typography variant="h6" className={classes.title}>
            {"Sampling rate: " +
              Math.round(state.status.process_status.frequency_hz) + "Hz"}
          </Typography>
          <WebsocketIndicator readyState={readyState} />
        </Toolbar>
      </AppBar>
      <Container>
        <FirmwareControl      
        names={state.firmware.filenames}
        dispatchFunction={dispatchFun}
        isOpen={modalOpen}
        handleClose={closeModal}
      />
        <Grid
          container
          justify="space-around"
          spacing={2}
          className={classes.root}
          alignItems="stretch"
        >
          <Grid item xs={12} sm={12}>
            <Paper elevation={3} className={classes.paper}>
              <Typography>
                Targetlist (3 dominant targets)
              </Typography>
              <div>
                <ComputingRangeSlider
                  range={[
                    state.status.process_status.min_dist,
                    state.status.process_status.min_dist,
                  ]}
                  dispatch={dispatchFun}
                />
                <TargetListPlot
                  inputMsg={state.targets}
                  dispatch={dispatchFun}
                />
              </div>
            </Paper>
          </Grid>

          <Grid item xs={6} sm={6}>
            <Paper elevation={3} className={classes.paper}>
              <Typography>
                Magnitudes
              </Typography>
              <div>
                <ValueListPlot inputMsg={state.targets} />
              </div>
            </Paper>
          </Grid>

          <Grid item xs={6} sm={6}>
            <Paper elevation={3} className={classes.paper}>
              <Typography>
                Serial sender
              </Typography>
              <SerialComm dispatch={dispatchFun} />
              <SerialLine serialMsg={state.serialMsg} />
              <ControlPanel dispatch={dispatchFun} />
            </Paper>
          </Grid>

          {
            /*

          <Grid item xs={6} sm={6}>
              <iframe style={{width:"100%", height:"100%", minHeight:"400px"}} src='http://livedemo120-cam.wellenzahl.online'></iframe>
          </Grid> */
          }

          <Grid item xs={12} sm={6}>
            <Paper elevation={3} className={classes.paper}>
              <Typography>
                ADC Data
              </Typography>
              <RawDataPlot
                raw_data={state.rawData}
                ylabel="LSB [-]"
                xlabel="Samplenumber [#]"
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} className={classes.paper}>
              <Typography>
                Spectrum
              </Typography>
              <RawDataPlot
                raw_data={state.specData}
                ylabel="FFT Value (up-ramp) [-]"
                xlabel="distance [m]"
              />
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper} elevation={3}>
              <Button
                endIcon={state.getData
                  ? <PauseIcon style={{ color: red[300] }} />
                  : <PlayArrowIcon style={{ color: green[300] }} />}
                style={{ width: "40%", float: "left" }}
                variant="outlined"
                onClick={() => {
                  dispatchFun({ type: "radar:raw" });
                  dispatch({ type: "GETDATA" });
                }}
              >
                {state.getData ? "Pause raw data" : "Start raw data"}
              </Button>

              <Radar status={state.status} dispatch={dispatchFun} />

              <br></br>
              <HeaderVis header={state.targets.header} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} className={classes.paper}>
              <DownloadWidget
                downloadList={state.status.downloads}
                record={state.status.radar_recorder}
                dispatch={dispatchFun}
              >
              </DownloadWidget>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} className={classes.paper}>
               <Button variant="contained" color="error" onClick={() => {dispatchFun({ type: "radar:firmware" }); openModal();}}>Firmware Update</Button>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Copyright></Copyright>
    </main>
  );
}

export default App;

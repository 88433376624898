import React from 'react';
import { Button } from '@material-ui/core';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { red, green } from '@material-ui/core/colors';

const Radar = ({ status, dispatch }) => {

    const Btn = () => {
        if (status.activate) {
            return (
                <Button style={{float: 'right', width: "40%"}} startIcon={<PowerSettingsNewIcon style={{ color: red[300]}}/>} onClick={() => dispatch({ type: "radar:deactivate", payload: "" })} variant="outlined" >Deactivate Radar</Button>
            )
        }
        else {
            return (
                <Button style={{float: 'right', width: "40%"}} startIcon={<PowerSettingsNewIcon style={{color: green[300]}}/>} variant="outlined" onClick={() => dispatch({ type: "radar:activate", payload: "" })}>Activate Radar</Button>
            );
        }
    }
    return (
               <Btn />

    );
}
export default Radar;
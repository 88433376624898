import React, { useState } from 'react'
import { red, green } from '@material-ui/core/colors';
import { Slider } from '@material-ui/core';

function valuetext(value) {
    return `${value}m`;
}


const ComputingRangeSlider = ({ range, dispatch }) => {
    const [state, setState] = useState({dispatched: true, value: [0.25, 15] })
    const handleChange = (event, value) => {
        setState({dispatched: false, value: value});

    }
    const marks = [
           {
        value: 0,
            label: '0m',
    },
    
    {
        value: 40,
            label: '40m',

    },

    ]




return (
    <>
<Slider
    style={{float: "left", height: "360px", color: state.dispatched ? green[300]: red[300] }}
    orientation="vertical"
    value={state.value}
    onChange={handleChange}
    onChangeCommitted={(e) => {
        dispatch({ type: "radar:set_distances", payload: { min_dist: state.value[0], max_dist: state.value[1] } });
        setState({...state, dispatched: true});
    }}
    valueLabelDisplay="auto"
    getAriaValueText={valuetext}
    marks={marks}
    max={40}
    step={.1}
/>
</>);
}

export default ComputingRangeSlider;
import React, { useEffect, useState } from "react";
import Plot from 'react-plotly.js';
import { Slider, TextField } from "@material-ui/core";
import { MapController } from "react-map-gl";


function valuetext(value) {
    return `${value}m`;
}

const marks = [
    {
        value: 0,
        label: '0m',
    },
    {
        value: 4,
        label: '4m',
    },
    {
        value: 12,
        label: '12m',
    },
    {
        value: 40,
        label: '40m',
    },
];


function scaleThreshold(x, threshold){

    let th = parseFloat(threshold);
    let fx = 7. / ((x + 2.) / 10.);
    return fx + th;
}



const TargetListPlot = React.memo(({ inputMsg, dispatch }) => {
    const [targetList, setTargetList] = useState([
        { x: [], y: [], type: 'scattergl', mode: 'markers' },
        { x: [], y: [], type: 'scattergl', mode: 'markers' },
        { x: [], y: [], type: 'scattergl', mode: 'markers' }]);
    const [value, setValue] = useState([.2, 12.1]);
    const [threshold, setThreshold] = useState(20);

    const append_list = (msg) => {
        setTargetList(tlist => {


            const res = tlist.map((tl, i) => {
                var arr;
                var value = msg.targets[i];
                if(value === 0)
                {
                    //if ((msg.header.timestamp_read - tl.x[0]) > 60) {
                    //                    tl.x.shift();
                    //                    tl.y.shift();
                    //return tl;
                    value = -1;
                }/*else{
                    let scThres = scaleThreshold(msg.targets[i], threshold)
                    if((20 * Math.log10(msg.values_abs[i])) < scThres)
                    {
                        value = -1;
                    }

                }*/
                arr = {
                    x: [...tl.x, msg.header.timestamp_read],
                    y: [...tl.y, value], type: 'scattergl', mode: 'markers'
                }


                if (arr.x.length > 512 || (msg.header.timestamp_read - arr.x[0]) > 30) {
                    arr.x.shift();
                    arr.y.shift();
                }
                return arr;
            });
            setTargetList(res);


        });
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const thresholdChange = (event) =>{

        dispatch({ type: "radar:threshold", payload: {threshold: event.target.value} });
        setThreshold(event.target.value)
    }


    useEffect(() => {
        if (inputMsg.header.timestamp_read) {
            append_list(inputMsg);
        }

    }, [inputMsg]);

    return (
        <>



            <Slider
                style={{ float: "left", height: "360px"}}
                orientation="vertical"
                value={value}
                onChange={handleChange}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                getAriaValueText={valuetext}
                marks={marks}
                max={40}
                step={.1}

            />

 
            <Plot style={{float:"rigth", paddingLeft: 60, height: "500px" }} data={targetList}
                layout={{
                    paper_bgcolor: 'rgba(0,0,0,0)',
                    plot_bgcolor: 'rgba(0,0,0,0)',
                    showlegend: true,
                    legend: { "orientation": "h" },

                    xaxis: { showgrid: true, showline: true, gridwidth: 1, gridcolor: '#bbbbbb' },
                    yaxis: { range: value, showgrid: true, showline: true, gridwidth: 1, gridcolor: '#bbbbbb' }
                }} />
        </>
    );
});

export default TargetListPlot;

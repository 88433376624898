import React from 'react';
import { Typography, Link } from '@material-ui/core';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="www.wellenzahl.de">
        Wellenzahl Radar- und Sensortechnik GmbH &amp; Co. KG  {new Date().getFullYear()} <br></br>
      </Link> <br></br>

      {'.'}
    </Typography>
  );
}


export default Copyright;
import React, { useEffect, useState } from 'react';
import { List, Button, IconButton, makeStyles, TextField,  ListItemText, ListItem, ListItemIcon, ListItemSecondaryAction, Typography, Divider, Box } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import StopIcon from '@material-ui/icons/Stop';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
    },
    button: {
        margin: theme.spacing(1),
        background: green[300],
        textTransform: 'none',
        width: '80%'
    },
    textField: {
        margin: 10,
        width: "100%"
    },
    recordBtn:{

        margin: theme.spacing(1),
        float: "left"
    }
}))

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}


export const DownloadWidget = React.memo(({ downloadList, record, dispatch }) => {
    const classes = useStyles();
    const [description, setDescription] = useState("");
    const [postfix, setPostfix] = useState("");

    useEffect(() => {

        if (record.started) {
            if (record.description) {
                setDescription(record.description);
                setPostfix(record.postfix);
            }
        }

    }, [record]);


    const lst = downloadList.map((value, i) => (<ListItem button key={i}>

        <ListItemLink href={"/download/" + value}>
            <ListItemIcon style={{ color: green[300] }}>
                <CloudDownloadIcon />
            </ListItemIcon>
            <ListItemText primary={value} />
        </ListItemLink>
        <ListItemSecondaryAction>
            <IconButton style={{ color: red[300] }} edge="end" aria-label="delete" onClick={() => dispatch({ type: "radar:delete_file", payload: value })} >
                <DeleteIcon />
            </IconButton>
        </ListItemSecondaryAction>
    </ListItem>));


    const Btn = () => {
        if (record.started) {
            return (
                    <Button className={classes.recordBtn} variant="outlined" endIcon={<StopIcon />} onClick={() => dispatch({ type: "radar:record_stop", payload: "" })}>Stop</Button>
            );
        }
        else {
            return (<Button className={classes.recordBtn} variant="outlined" endIcon={<FiberManualRecordIcon style={{ color: red[500] }}></FiberManualRecordIcon>}
                onClick={() => dispatch({
                    type: "radar:record_start",
                    payload: { description: description, postfix: postfix }
                })}>Start</Button>)
        }
    }

    return (
        <Box  >
            <Typography variant='h6'>
                Recorder
            </Typography>
            <Divider />
            <div>            <TextField className={classes.textField} label="description"
                variant="outlined" value={description} onChange={e => setDescription(e.target.value)} />
            </div>
            <div>

                <TextField label="postfix" className={classes.textField}
                    variant="outlined" value={postfix} onChange={e => setPostfix(e.target.value)} />
            </div>

            <Btn></Btn>

            <br/>
            <br/>
            <br/>
            <Typography variant="h6" >
                List of Data

          </Typography>
            <Divider />
            <div>
                <List className={classes.root} height={200}>
                    {lst}
                </List>
            </div>

        </Box>
    )

})

export default DownloadWidget;
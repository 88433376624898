import React from 'react';
import { Typography } from '@material-ui/core';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import { green, red, orange, grey, blue } from '@material-ui/core/colors';

const CONNECTION_STATUS_CONNECTING = 0;
const CONNECTION_STATUS_OPEN = 1;
const CONNECTION_STATUS_CLOSING = 2;
const CONNECTION_STATUS_CLOSED = 3;

const getStyle = (rState) => {
    switch (rState) {
        case CONNECTION_STATUS_CLOSED:
            return (<CancelPresentationIcon style={{ fontSize: 30, color: red[500] }} />);
        case CONNECTION_STATUS_CLOSING:
            return (<CancelPresentationIcon style={{ fontSize: 30, color: orange[500] }} />);
        case CONNECTION_STATUS_CONNECTING:
            return (<AutorenewIcon style={{ fontSize: 30, color: blue[500] }} />);
        case CONNECTION_STATUS_OPEN:
            return (<SettingsEthernetIcon style={{ fontSize: 30, color: green[500] }} />);
        default:
            return (<CancelPresentationIcon style={{ fontSize: 30, color: grey[500] }} />);

    }
}

export const WebsocketIndicator = ({ readyState }) => {
    const connectionStatus = {
        [CONNECTION_STATUS_CONNECTING]: 'Connecting',
        [CONNECTION_STATUS_OPEN]: 'Open',
        [CONNECTION_STATUS_CLOSING]: 'Closing',
        [CONNECTION_STATUS_CLOSED]: 'Closed',
    }[readyState];

    const stateStyle = getStyle(readyState);

    return (
        <>
            <span style={{ display: "flex" }}>
                <Typography variant='h6' style={{marginRight: 10}}>{connectionStatus}</Typography>
                {stateStyle}
            </span>
        </>
    );
}
export default WebsocketIndicator;
import React, {useState} from 'react';
import {Button, Input } from '@material-ui/core';


export const SerialComm = ({dispatch}) => {
    const [serialCommand, setSerialCommand] = useState('')

    const dispatchLocal = () => {

        dispatch({type: "radar:serial", payload: {'message': serialCommand}});
        setSerialCommand('');
    }
    return (
        <>
        <Input value={serialCommand} onKeyPress={e => {if (e.key === "Enter") dispatchLocal()}} 
        onChange={e => setSerialCommand(e.target.value) }/>
        <Button disabled={serialCommand === '' ? true: false} onClick={dispatchLocal}>Send</Button>
        </>
    )
}

export default SerialComm;
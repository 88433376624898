import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Slider,
  Typography,
} from "@material-ui/core";

function valuetext(value) {
  return `${value}GHz`;
}

const freqMarks = [
  { value: 58, label: "58 GHz" },
  { value: 61.5, label: "61.5 GHz" },
  { value: 62.5, label: "62.5 GHz" },
];

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  slider: {
    width: 300,
    margin: 20,
  },
});

function ControlPanel({ dispatch }) {
  const classes = useStyles();
  const [frequency, setFrequency] = useState(58);
  const [stopFrequency, setStopFrequency] = useState(62.5);
  const [opamp, setOpamp] = useState(2);
  const [amplifier, setAmplifier] = useState(1);
  const [fmcw, setFmcw] = useState(false);
  const [coupling, setCoupling] = useState(false);
  const [sweeptimeIdx, setSweeptimeIdx] = useState(1);
  const [samples, setSamples] = useState(128);

  const handleFrequencyChange = (event, newValue) => {
    setFrequency(newValue);
  };

  const handleStopFrequencyChange = (event, newValue) => {
    setStopFrequency(newValue);
  };

  const handleOpampChange = (event, newValue) => {
    setOpamp(newValue);
  };

  const handleAmplifierChange = (event, newValue) => {
    setAmplifier(newValue);
  };

  const handleSweeptimeChange = (event, newValue) => {
    setSweeptimeIdx(newValue);
  };

  const handleSamplesChange = (event, newValue) => {
    setSamples(newValue);
  };

  const handleFmcwChange = (event) => {
    setFmcw(event.target.checked);
    var msg = "#Z:0d:3+!";
    if (event.target.checked) {
      msg = "#Z:0d:0+!";
    }
    dispatch({ type: "radar:serial", payload: { message: msg } });
  };

  const handleCouplingChange = (event) => {
    setCoupling(event.target.checked);
    var msg = "#Z:23:0+";
    if (event.target.checked) {
      msg = "#Z:23:15+";
    }
    dispatch({ type: "radar:serial", payload: { message: msg } });
  };

  const handleDispatch = (cmd, value) => {
    let msg = "#Z:" + cmd + ":" + value + "+!";

    const fun = (e) => {
      console.log("handleDispatch: " + msg);
      dispatch({ type: "radar:serial", payload: { message: msg } });
    };
    return fun;
  };

  const handleAmplifier = (value) => {
    let msg = "#Z:5:" + value + "+" + "#Z:6:" + value + "+!";
    const fun = (e) =>
      dispatch({ type: "radar:serial", payload: { message: msg } });
    return fun;
  };

  const handleFlash=  (e) => {
    let msg = "#A+";
    dispatch({ type: "radar:serial", payload: { message: msg } });
  };
  const handleClick = (e) => {
    let msg = "!";
    dispatch({ type: "radar:serial", payload: { message: msg } });
  };

  const sendFreq = (e) => {
    console.log("sendFreqe");
    dispatch({
      type: "radar:mqtt",
      payload: { type: "freqStart", payload: frequency },
    });
  };

  return (
    <div className={classes.root}>
      <Typography variant="body2" gutterBottom>
        Frequenz (GHz): {frequency}
      </Typography>
      <Slider
        className={classes.slider}
        value={frequency}
        min={58}
        max={62.5}
        step={0.1}
        onChange={handleFrequencyChange}
        onChangeCommitted={handleDispatch(2, frequency)}
        aria-labelledby="frequency-slider"
        getAriaValueText={valuetext}
        marks={freqMarks}
      />
      <Typography variant="body2" gutterBottom>
        stop Frequenz (GHz): {frequency}
      </Typography>
      <Slider
        className={classes.slider}
        value={stopFrequency}
        min={frequency}
        max={62.5}
        step={0.1}
        onChange={handleStopFrequencyChange}
        onChangeCommitted={handleDispatch(3, frequency)}
        aria-labelledby="frequency-slider"
        getAriaValueText={valuetext}
        marks={[{ value: frequency, label: frequency + "GHz" }, {
          value: 62.5,
          label: "62.5 GHz",
        }]}
      />
      <Typography variant="body2" align="left" gutterBottom>
        Opamp {opamp}
      </Typography>
      <Slider
        className={classes.slider}
        value={opamp}
        min={1}
        max={7}
        step={1}
        marks={[{ value: 1, label: "1" }, { value: 4, label: "4" }, { value: 7, label: "7" }]}
        onChange={handleOpampChange}
        onChangeCommitted={handleDispatch(5, opamp)}
        aria-labelledby="opamp-slider"
      />
      <Typography variant="body2" align="left" gutterBottom>
        Apmlifier {amplifier}
      </Typography>
      <Slider
        className={classes.slider}
        value={amplifier}
        min={0}
        max={31}
        onChange={handleAmplifierChange}
        onChangeCommitted={handleDispatch(6, amplifier)}
        aria-labelledby="amplifier-slider"
        marks={[{ value: 0, label: "0" }, { value: 255, label: "255" }]}
      />
      <Typography variant="body2" align="left" gutterBottom>
        Sweeptime Index {sweeptimeIdx}
      </Typography>
      <Slider
        className={classes.slider}
        value={sweeptimeIdx}
        min={1}
        max={14}
        step={1}
        marks={[{ value: 1, label: "1" }, { value: 14, label: "14" }]}
        onChange={handleSweeptimeChange}
        onChangeCommitted={handleDispatch("0c", sweeptimeIdx)}
        aria-labelledby="sweeptime-slider"
      />

      <Typography variant="body2" align="left" gutterBottom>
        Samples {samples}
      </Typography>
      <Slider
        className={classes.slider}
        value={samples}
        min={128}
        max={2048}
        marks={[{ value: 128, label: "128" }, { value: 2048, label: "14400" }]}
        step={10}
        onChange={handleSamplesChange}
        onChangeCommitted={handleDispatch("12", samples)}
        aria-labelledby="samples-slider"
      />

      <div>
        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox
              checked={fmcw}
              onChange={handleFmcwChange}
              color="primary"
            />
          }
          label="FMCW"
          labelPlacement="start"
        />

        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox
              checked={coupling}
              onChange={handleCouplingChange}
              color="primary"
            />
          }
          label="AC Coupling"
          labelPlacement="start"
        />
      </div>

      <div>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 10 }}
          onClick={handleFlash}
        >
          Save to flash
        </Button>

      </div>
    </div>
  );
}

export default ControlPanel;

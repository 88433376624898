
import React from "react";
import { Typography } from '@material-ui/core';


export const SerialLine = ({ serialMsg }) => {
    return (
        <>
          
          <Typography variant="h6" gutterBottom align="left">
            :{'>'} {serialMsg}
          </Typography>
        </>
    );
}
